@import "variables";

.header {
    background: $header-color;
    height: 90px !important;
    padding: 15px;

    #auris-logo {
        margin-top: -15px;
        height: 90px;
    }

    h1 {
        text-align: center;
    }

    .lang-logo {
        height: 1.5rem;
        width: 1.8rem;
        margin-right: 1rem;
    }

    .dropdown-menu {
        height: revert;
    }

    .btn {
        &:focus,
        &:active {
            box-shadow: none !important;
            border-style: none !important;
        }
        padding: 1em !important;
    }
}
