$primary-color: #333366;
$primary-color-hover: #222244;
$primary-color-outline: #7777bb;
$secondary-color: red;
$secondary-color-hover: #c60000;
$secondary-color-outline: #ff6666;
$background-color: #f2f2f7;
$header-color: #ffffff;
$footer-color: #f2f2f7;
$footer-outline: #333366;
$primary-font-color: #333366;
$gradient-orange: #ff7f01;
$gradient-red: #eb3162;
$gradient-outline: #ff5429;
$marked-cell: #dadade;
