@import "variables";

.login-container {
    border: 2px solid $primary-color;
    border-radius: 0.5rem;
    width: 100% !important;
    height: 20rem !important;
    margin-bottom: 3rem;
    > div {
        width: 70%;
        margin: 10% 0 0 15%;
        height: 70%;
        p {
            text-align: center;
        }
        .btn {
            margin-left: 25%;
            width: 50%;
        }
    }

    #pin-btn,
    #pin-submit,
    #pin-input {
        font-size: 0.8rem !important;
        width: 30%;
        margin-left: 35%;
    }
}

h2 {
    padding-left: 15px;
}

.home {
    padding-top: 5rem;
}

#contact > p {
    margin-bottom: 0.5rem;
}

.msLogo {
    height: 1.3rem;
    margin-right: 1rem;
}

.info-block {
    > .text {
        padding-left: 0px;
    }
}

.login-block {
    margin-top: 5rem;
}
