@mixin button($color, $hover, $outline) {
    &,
    &:focus {
        background-color: $color;
        border: 2px solid $color;
        transition: 0.2s;
        &[class*="gradient"] {
            box-shadow: 0 1rem 20px 5px #{$hover}20;
        }

        &:focus,
        &:active {
            box-shadow: none !important;
        }

        &:hover,
        &:active {
            background-color: $hover !important;
            border: 2px solid $hover !important;
            &[class*="gradient"] {
                transform: scale(1.02);
                border: 2px solid rgba(0, 0, 0, 0) !important;
            }
        }

        &[class*="outline"] {
            background-color: white;
            color: $color;
            border: 2px solid $color;

            &:hover {
                background-color: $color !important;
                border: 2px solid $color !important;
                color: white;
            }

            &[class*="small"] {
                &:hover,
                &:active,
                &:focus {
                    background-color: white !important;
                    color: $color !important;
                    border: 2px solid $color !important;
                }
            }
        }
    }
}
