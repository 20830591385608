.upload-container {
    margin: 5rem auto;
    > .upload-box {
        width: 70%;
        height: 100%;
        background-color: white;
        border-radius: 0.3rem;
        padding: 1rem 10%;

        .file-dropbox {
            margin: 5% 0;
            width: 100%;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23ff5429' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
            padding: 2rem;
            border-radius: 0.1rem;
            position: relative;

            > p {
                text-align: center;
            }
        }

        .file-input {
            &.form-file {
                width: 50% !important;
                margin-left: 25%;
            }

            .form-control-file {
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            label {
                margin-top: 0;
                margin-bottom: 0;
            }
        }

        #btn-result {
            font-size: large;
            margin: 2em 20%;
            width: 60%;
            color: white;
        }

        .file-container {
            * {
                font-size: 0.9rem;
            }

            .list-group-item {
                padding: 0em;
                padding-top: 0.4em;

                .delete {
                    padding: 0.1em 0.3em !important;
                    position: absolute;
                    right: 6px;
                    margin-bottom: 0;
                    border-radius: 0.3em !important;

                    &:active,
                    &:focus {
                        box-shadow: none !important;
                        border-style: none !important;
                    }
                }

                div {
                    margin: 0.3em !important;
                    display: inline-block;
                    max-width: 75%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}
