@import "variables";

.table-container {
    border: 1px solid $primary-color;
    border-radius: 0.2em;
    padding: 0 !important;
    overflow-x: auto;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }

    &::-webkit-scrollbar-track {
        background: $background-color;
    }

    &::-webkit-scrollbar-thumb {
        background: $primary-color;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: $primary-color-hover;
    }

    &::-webkit-scrollbar-corner {
        background: $background-color;
    }

    max-height: 500px;

    .table {
        margin: 0;
        background-color: white;
        color: $primary-color;

        th,
        td {
            vertical-align: top;
            border-style: none none solid none !important;
            border-color: $primary-color !important;
            border-width: 1px !important;
        }

        tr:last-child > td,
        tbody > tr:last-child > th {
            border-bottom-style: none !important;
        }

        td.marked {
            background-color: $marked-cell;
            font-weight: bold;
        }

        .border-right {
            border-right-style: solid !important;
        }

        .info-logo {
            width: 0.8em;
            margin-left: 0.2em;
        }
    }
}

.modal-dialog {
    max-width: 50% !important;
}
