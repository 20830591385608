@import "variables";

.footer {
    font-size: small;
    background: $footer-color;
    border-top: 1px solid $footer-outline;
    padding: 1.5%;

    p {
        margin-bottom: 0px;
    }

    #itslanguage-logo {
        height: 30px;
    }
}
