@import "variables";

.results-block {
    > .container {
        margin: 2em 1em;
    }

    .description {
        margin-left: (-15px);
    }

    #excel {
        position: absolute;
    }
    .btn-gradient {
        position: absolute;
        right: 3em;
        color: white;
        &:hover {
            color: white;
        }
    }

    .form-inline {
        label {
            margin-bottom: 1rem;
            margin-right: 0.5rem;
        }

        #update-input {
            width: 6em;
            border-radius: 0.3em 0 0 0.3em !important;
            height: 40px;
            box-shadow: none;
        }

        .btn,
        .form-control {
            border-radius: 0.5em !important;
            padding: 0em 0.5em !important;
            border-width: 1px !important;
        }

        #update-btn {
            border-radius: 0 0.3em 0.3em 0 !important;
            height: 40px;
            width: 6em;

            &:hover,
            &:focus,
            &:focus {
                border-width: 1px !important;
            }
        }
    }

    a.nav-item {
        text-decoration: none !important;

        &.active {
            border-width: 1px;
            border-color: $primary-color;
            border-style: solid solid none solid !important;
        }
    }

    .dropdown-item.selected {
        background-color: $primary-color-outline;
    }

    .dropdown-toggle.btn-dropdown {
        border-radius: 0.3em !important;
        border: 1px solid $primary-color !important;
        padding: 0.5em 0.7em !important;
        min-width: 10em;
        text-align: left;
        position: relative;
        background-color: white;
        color: $primary-color;
        margin-right: 1em;
        box-shadow: none;

        &:hover {
            background-color: $primary-color !important;
            border: 1px solid $primary-color !important;
            color: white;
        }

        &::after {
            position: absolute;
            right: 1em;
            top: 40%;
        }
    }
}
