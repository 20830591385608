@import "variables";
@import "functions";
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600&display=swap");

html,
body,
#root,
.app {
    color: $primary-font-color;
    height: 100%;
    background-color: $background-color;
    font-family: "Source Sans Pro", sans-serif;

    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
}

.header {
    height: 10%;
}

.footer {
    height: 8%;
}

.header + div {
    min-height: 65%;
}

.btn {
    border-radius: 25px !important;
    padding: 0.4rem 2rem !important;
    margin-bottom: 1rem;

    &[class*="primary"] {
        @include button($primary-color, $primary-color-hover, $primary-color-outline);
    }

    &[class*="secondary"] {
        @include button($secondary-color, $secondary-color-hover, $secondary-color-outline);
    }

    &[class*="gradient"] {
        background-image: linear-gradient(to right, $gradient-red, $gradient-orange);
        background-repeat: repeat-y;
        background-size: 105% !important;
        background-position: -2px !important;
        @include button(rgba(0, 0, 0, 0), $gradient-outline, $gradient-outline);
    }
}

.form-control {
    border-radius: 25px !important;
    border: 1px solid $primary-font-color !important;
    margin-bottom: 1rem;
}

textarea {
    height: 10rem !important;
    resize: none !important;
    padding-top: 0.8em !important;
}

.alert {
    position: fixed !important;
    margin-bottom: 0px !important;
    bottom: 0;
    left: 0;
}

a {
    color: $primary-font-color !important;
    text-decoration: underline !important;
}

.app > .nav {
    position: absolute;
    top: 20px;

    .dropdown-toggle {
        box-shadow: none;

        &::after {
            display: none;
        }
    }
}
